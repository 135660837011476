







import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('config', ['readOnlyMessage']),
    bannerMessage(): string {
      return this.readOnlyMessage as unknown as string;
    },
  },
};
