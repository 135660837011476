




















import Vue from 'vue';
import getViewPathParts from '@/util/getViewPathParts';

interface ShareUrlModalData {
  hasCopied: boolean;
}

export default Vue.extend({
  name: 'share-url-modal',
  data(): ShareUrlModalData {
    return {
      hasCopied: false,
    };
  },
  computed: {
    url() {
      const { origin } = window.location;
      const { baseUrl } = getViewPathParts();

      return `${origin}${baseUrl}`;
    },
    buttonMessage(): string {
      return this.hasCopied ? 'Copied!' : 'Copy';
    },
  },
  watch: {
    hasCopied() {
      if (this.hasCopied) {
        setTimeout(() => {
          this.hasCopied = false;
        }, 1000);
      }
    },
  },
});
