export const authUrlGenerator = (location: Location): string => {
  let redirectHost;
  const { protocol, hostname: host } = location;

  if (host === 'localhost') {
    redirectHost = 'localhost:8080';
  } else {
    const splitHost = host.split('.');
    let envPath;
    if (splitHost[0] === 'share') {
      if (splitHost[1]?.toLowerCase() === 'vetro') {
        envPath = 'vetro.io';
      } else {
        envPath = `${splitHost[1]}.vetro.io`;
      }
    } else {
      envPath = 'vetro.io';
    }
    redirectHost = `auth.${envPath}`;
  }
  return `${protocol}//${redirectHost}/login?redirect=${location.origin}${location.pathname}`;
};
