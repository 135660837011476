// Load this first so the injected CSS is loaded before anything else
import './assets/custom.scss';

import Vue from 'vue';
import axios from 'axios';
import Vuelidate from 'vuelidate';
import pipeline from 'vetro-response-pipelines';
import { CameraOptions, Map } from 'mapbox-gl';
import setupGlobalDependencies from './setupGlobalDependencies';
import App from './App.vue';
import router from './router';
import store from './store';

setupGlobalDependencies(Vue);

if (process.env.VUE_APP_ENABLE_AXE) {
  // eslint-disable-next-line
  const VueAxe = require('vue-axe').default;
  Vue.use(VueAxe);
}

if (process.env.VUE_APP_VETRO_API_URL) {
  // This should include the scheme, ex: VUE_APP_VETRO_API_URL=https://some.url
  axios.defaults.baseURL = process.env.VUE_APP_VETRO_API_URL;
} else {
  throw Error('No Vetro API URL configured');
}

pipeline.config(axios);

Vue.config.productionTip = false;

Vue.use(Vuelidate);

// Sets up the global functions to be used when setting up UserFlows
declare global {
  interface Window {
    displayLayers: (layerIds: number[]) => void;
    openSidebar: () => void;
    closeSidebar: () => void;
    mapJumpTo: (options: CameraOptions) => void;
    map: Map;
  }
}

window.displayLayers = (layerIds: number[]) => {
  store.dispatch('layers/displayLayers', layerIds);
};
window.openSidebar = () => {
  store.dispatch('sidebar/openSidebar');
};
window.closeSidebar = () => {
  store.dispatch('sidebar/closeSidebar');
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
