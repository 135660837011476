import { Geometries } from '@turf/helpers';

export enum FeatureTables {
  NetworkLines = 'network_lines',
  NetworkPoints = 'network_points',
  NonnetworkLines = 'nonnetwork_lines',
  NonnetworkPoints = 'nonnetwork_points',
  NonnetworkPolygons = 'nonnetwork_polygons',
}

export enum FeatureTypes {
  Duct = 'duct',
  Microduct = 'micro_duct',
  MicroductBundle = 'micro_duct_bundle',
  FiberCable = 'fiber_cable',

  NetworkPoint = 'network_point',
  ChiefOffice = 'chief_office',
  ServiceLocation = 'service_location',
  Junction = 'junction',

  Muxer = 'equipment_muxer',
  NxNEquipment = 'equipment_nxn',
  Splitter = 'equipment_splitter',
  SpliceClosure = 'equipment_splice_closure',

  NonnetworkLine = 'nonnetwork_line',
  NonnetworkPoint = 'nonnetwork_point',
  NonnetworkPolygon = 'nonnetwork_polygon',
  SlackLoop = 'slack_loop',
  Hardware = 'hardware',
  Pole = 'pole',
}

export type NullableGeometry = Geometries | null;

export interface Feature<TGeomType extends NullableGeometry = NullableGeometry> {
  xVetro: {
    id?: number;
    planId: number;
    layerId: number;
    vetroId?: string;
    hasParent?: boolean;
    featureType?: FeatureTypes;
    featureTable?: FeatureTables;
    childVetroIds?: string[];
    parentVetroId?: string | null;
    hasCustomLength?: boolean;
    laborTemplateId?: number | null;
    hardwareTemplateId?: number | null;
    materialTemplateId?: number | null;
    createdTime?: string;
    child?: Feature;
  };
  geometry?: TGeomType;
  properties: Record<string, string | number | undefined>;
}

export interface FeatureUpsertResult {
  duplicateFeatures: boolean;
  requestedFeature: Feature;
  vetroFeatures: Feature[];
}

export interface FeatureImageAttributeDomainResponse {
  attributeDocuments: string;
  images: string;
}

export interface ParentChildPair {
  parent: Feature | null;
  child: Feature;
}
