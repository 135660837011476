import { MutationTree, ActionTree } from 'vuex';

import type { RootState } from '@/store';
import { MutationTypes } from '@/constants';

export class ErrorState {
  criticalError: Error | null = null;
}

export const mutations: MutationTree<ErrorState> = {
  [MutationTypes.CRITICAL_ERROR](state: ErrorState, error: Error) {
    state.criticalError = error;
  },
};

export const actions: ActionTree<ErrorState, RootState> = {
  setCriticalError({ commit }, error: Error) {
    // TODO: Log this error, probably to Sentry
    commit(MutationTypes.CRITICAL_ERROR, error);
    return error;
  },
};

export default {
  namespaced: true,
  state: (): ErrorState => new ErrorState(),
  mutations,
  actions,
};
