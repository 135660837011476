export enum MutationTypes {
  // config
  SET_CONFIG = 'SET_CONFIG',
  // error
  CRITICAL_ERROR = 'CRITICAL_ERROR',
  // sidebar
  TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
  OPEN_SIDEBAR = 'OPEN_SIDEBAR',
  CLOSE_SIDEBAR = 'CLOSE_SIDEBAR',
  ADD_SURVEY_DATA = 'ADD_SURVEY_DATA',
  SET_SUBMISSION_REDIRECT_URL = 'SET_SUBMISSION_REDIRECT_URL',
  SET_SURVEY_ACTIVE = 'SET_SURVEY_ACTIVE',
  RESET_SIDEBAR_STATE = 'RESET_SIDEBAR_STATE',
  SET_FORM_VALIDITY = 'SET_FORM_VALIDITY',
  SET_ERROR_STATE = 'SET_ERROR_STATE',
  RESET_PAGINATION_INDEX = 'RESET_PAGINATION_INDEX',
  SET_PAGINATION_INDEX = 'SET_PAGINATION_INDEX',
  // layers
  SET_CATEGORIES = 'SET_CATEGORIES',
  SET_LAYERS = 'SET_LAYERS',
  SET_CATEGORIZED_ATTRIBUTE = 'SET_CATEGORIZED_ATTRIBUTE',
  TOGGLE_LAYERS = 'TOGGLE_LAYERS',
  TOGGLE_CATEGORIZED_ATTRIBUTES = 'TOGGLE_CATEGORIZED_ATTRIBUTES',
  // features
  SET_SELECTED_FEATURES = 'SET_SELECTED_FEATURES',
  SET_SELECTED_FEATURE_HIGHLIGHT_STATUS = 'SET_SELECTED_FEATURE_HIGHLIGHT_STATUS',
  SET_SELECTED_FEATURE_SURVEY_VISIBILITY = 'SET_SELECTED_FEATURE_SURVEY_VISIBILITY',
  RESET_FEATURE_STATE = 'RESET_FEATURE_STATE',
  SET_IMAGE_ATTRIBUTE_DOMAIN = 'SET_IMAGE_ATTRIBUTE_DOMAIN',
  SET_FILE_ATTRIBUTE_DOMAIN = 'SET_FILE_ATTRIBUTE_DOMAIN',
  SET_FEATURES_AT_FOCUS_POINT = 'SET_FEATURES_AT_FOCUS_POINT',
  FILTER_SELTECTED_FEATURES = 'FILTER_SELTECTED_FEATURES',
  ADD_FEATURE = 'ADD_FEATURE',
  // search
  SET_ADDRESS_PREDICTIONS = 'SET_ADDRESS_PREDICTIONS',
  SET_SELECTED_ADDRESS = 'SET_SELECTED_ADDRESS',
  RESET_SEARCH_STATE = 'RESET_SEARCH_STATE',
  // spinners
  TOGGLE_SPINNER = 'TOGGLE_SPINNER',
  SET_SPINNER_STATE = 'SET_SPINNER_STATE',
  // map
  SET_MAP_POSITION_RESET_FLAG = 'SET_MAP_POSITION_RESET_FLAG',
  UNSET_MAP_POSITION_RESET_FLAG = 'UNSET_MAP_POSITION_RESET_FLAG',
  SET_ACTIVE_LOCATION = 'SET_ACTIVE_LOCATION',
  SET_MAP_ACTION = 'SET_MAP_ACTION',
  SET_MULTI_SELECT_ENABLED = 'SET_MULTI_SELECT_ENABLED',
  SET_MULTI_SELECT_DISABLED = 'SET_MULTI_SELECT_DISABLED',
  // vetro-vue-component-library AlertPlugin
  ALERT_SUCCESS = 'alert/successAlert',
  ALERT_FAILURE = 'alert/failureAlert',
  ALERT_WARN = 'alert/warnAlert',
  // polygon intersections
  SET_POLYGON_INTERSECTIONS = 'SET_POLYGON_INTERSECTIONS',
  SET_POLYGON_INTERSECTION_LOADING_STATUS = 'SET_POLYGON_INTERSECTION_LOADING_STATUS',
  // layer legend
  TOGGLE_LAYER_LEGEND = 'TOGGLE_LAYER_LEGEND',
  // comments
  SET_COMMENTS = 'SET_COMMENTS',
  SET_PARENTS = 'SET_PARENTS',
  SET_SHOW_COMMENTS_IN_MAP = 'SET_SHOW_COMMENTS_IN_MAP',
  SET_HOVERED_CLUSTER_INFO = 'SET_HOVERED_CLUSTER_INFO',
  // user
  SET_USER_INFO = 'SET_USER_INFO',
  // lifecycle
  SET_FEATURE_STATUSES = 'SET_FEATURE_STATUSES',
}

export enum IndexActions {
  //  Index Actions
  DECREMENT = 'DECREMENT',
  INCREMENT = 'INCREMENT',
  CLEAR = 'CLEAR',
}

export enum MapboxBasemap {
  basic = 'basic',
  streets = 'streets',
  light = 'light',
  dark = 'dark',
  satelliteStreets = 'satelliteStreets',
}

export const SIDEBAR_SUBHEADER_TEXT =
  'Enter an address above to center the map on an address, or click-and-drag to pan the map.';

export const DEFAULT_JUMP_ZOOM = 14;

// Must match the breakpoints we are using in bootstrap.
export enum SCREEN_BREAKPOINT_MIN {
  XS = 0,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
}

// Must match the sidebar widths defined in src/assets/variables.scss
export const SIDEBAR_SCREEN_WIDTH = new Map<SCREEN_BREAKPOINT_MIN, number>([
  [SCREEN_BREAKPOINT_MIN.XS, 0], // Full width, can't be defined in px. Zero is a convenient default.
  [SCREEN_BREAKPOINT_MIN.SM, 350], // $sidebar-width-sm
  [SCREEN_BREAKPOINT_MIN.MD, 400], // $sidebar-width-md
  [SCREEN_BREAKPOINT_MIN.LG, 450], // $sidebar-width-lg
  [SCREEN_BREAKPOINT_MIN.XL, 550], // $sidebar-width-xl
]);

// spinners

export const SIDEBAR_SPINNER = 'SIDEBAR_SPINNER';
export const COMMENT_SPINNER = 'COMMENT_SPINNER';

export const SELECTION_TYPE = {
  ADDRESS_SEARCH: 'addressSearch',
  FEATURE_SELECT: 'featureSelect',
};

export enum MAP_ACTION_TYPE {
  DO_NOTHING = 'DO_NOTHING',
  JUMP = 'JUMP',
}

export const SELECTION_MODES = {
  CLICK: 'CLICK',
  LASSO_SELECT: 'LASSO_SELECT',
  LASSO_DESELECT: 'LASSO_DESELECT',
  CLEAR: 'CLEAR',
};

export const NO_PARENT_ICON = {
  default: {
    iconSize: 10,
    fillColor: '#1154da',
    iconClass: 'circle',
    fillOpacity: 1,
  },
};

export const MAX_CATEGORIES = 120;

export const MAX_MULTI_SELECT = 50;
