import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { VetroResponse, isFailedResponse } from '@/types';
import axios from 'axios';
import type { RootState } from '@/store';
import { MutationTypes } from '@/constants';

type FeatureStatus = {
  id: number;
  label: string;
  displayOrder: number;
  lifecycleTypeId: number;
};

export class LifecycleState {
  featureStatuses = [];
}

export const mutations: MutationTree<LifecycleState> = {
  [MutationTypes.SET_FEATURE_STATUSES](state: LifecycleState, featureStatuses) {
    state.featureStatuses = featureStatuses;
  },
};

export const actions: ActionTree<LifecycleState, RootState> = {
  async fetchFeatureStatuses({ commit, dispatch }): Promise<void> {
    const { data: response }: { data: VetroResponse<FeatureStatus[]> } = await axios.get(
      '/v2/org/feature_statuses',
    );

    if (isFailedResponse(response)) {
      const error = new Error(`Failed to fetch feature statuses.`);
      dispatch('error/setCriticalError', error, { root: true });
      throw error;
    }

    commit(MutationTypes.SET_FEATURE_STATUSES, response.result);
  },
};

export const getters: GetterTree<LifecycleState, RootState> = {
  featureStatusLabelsById(state): Record<string, string> {
    return state.featureStatuses.reduce((acc, status: FeatureStatus) => {
      acc[status.id] = status.label;
      return acc;
    }, {} as Record<string, string>);
  },
};

export default {
  namespaced: true,
  state: (): LifecycleState => new LifecycleState(),
  mutations,
  actions,
  getters,
};
