import _cloneDeep from 'lodash.clonedeep';
import turfBBox from '@turf/bbox';
import { Feature } from '@turf/helpers';
import { GeoJSONSource, Map, PointLike } from 'mapbox-gl';

export type ClusterInfo = {
  id: number | null;
  isCluster: boolean;
  features: Feature[];
};

export const CLUSTER_SOURCE_ID = 'cluster-source';
export const CLUSTER_LAYER_ID = 'clusters';
export const UNCLUSTERED_LAYER_ID = 'unclustered-point';

export const getClusterLayer = (hoveredId: number | null): unknown => ({
  id: CLUSTER_LAYER_ID,
  type: 'circle',
  source: CLUSTER_SOURCE_ID,
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': [
      'case',
      ['==', ['get', 'cluster_id'], hoveredId],
      '#ffffff',
      ['step', ['get', 'point_count'], '#51bbd6', 1, '#f1f075', 5, '#f28cb1'],
    ],
    'circle-radius': ['step', ['get', 'point_count'], 20, 20, 30, 50, 40],
    'circle-blur': 0.1,
    'circle-stroke-width': 2,
    'circle-stroke-color': '#2b2b2b',
  },
});

export const CLUSTER_COUNT_LAYER = {
  id: 'cluster-count',
  type: 'symbol',
  source: CLUSTER_SOURCE_ID,
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
  },
};

export const getUnclusteredLayer = (hoveredId: number | null): unknown => ({
  id: UNCLUSTERED_LAYER_ID,
  type: 'circle',
  source: CLUSTER_SOURCE_ID,
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': ['case', ['==', ['get', 'vetroId'], hoveredId], '#ffffff', '#11b4da'],
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 0, 1, 10, 4, 12, 5, 15, 6, 22, 30],
    'circle-stroke-width': 2,
    'circle-stroke-color': '#2b2b2b',
  },
});

export const zoomToFeatures = (map: Map, features: Feature[]): void => {
  const bbox = turfBBox({
    type: 'GeometryCollection',
    geometries: features.map((feature) => feature.geometry),
  }) as [number, number, number, number];

  map.fitBounds(bbox, { padding: 125 });
};

export const getLeaves = async (map: Map, clusterId: number): Promise<Feature[]> =>
  new Promise((resolve) => {
    const clusterSource = map.getSource(CLUSTER_SOURCE_ID) as GeoJSONSource;

    clusterSource.getClusterLeaves(clusterId, Number.POSITIVE_INFINITY, 0, (err, leaves) => {
      if (err) {
        resolve([]);
      } else {
        resolve(leaves as Feature[]);
      }
    });
  });

export const emtpyClusterInfo: ClusterInfo = {
  id: null,
  isCluster: false,
  features: [],
};

export const getClusterPointsAtPoint = async (
  map: Map,
  point: PointLike,
  { includeUnclustered = false } = {},
): Promise<ClusterInfo> => {
  const unclustered = includeUnclustered ? [UNCLUSTERED_LAYER_ID] : [];
  const clusters = map.queryRenderedFeatures(point, {
    layers: [CLUSTER_LAYER_ID, ...unclustered],
  });

  if (!clusters.length) return emtpyClusterInfo;
  const [cluster] = clusters;

  if (cluster.layer.id === CLUSTER_LAYER_ID) {
    const clusterId: number = cluster?.properties?.cluster_id;
    if (!clusterId) return emtpyClusterInfo;

    const features = await getLeaves(map, clusterId);

    return {
      id: clusterId,
      isCluster: true,
      features,
    };
  }

  if (cluster.layer.id === UNCLUSTERED_LAYER_ID) {
    return {
      id: cluster?.properties?.vetroId,
      isCluster: false,
      features: [_cloneDeep(cluster) as Feature],
    };
  }

  return emtpyClusterInfo;
};
