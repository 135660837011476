import { LayerStyle, ViewConfigAttributes } from '@/types';
import { ViewConfigStyleValues } from '@/types/styles';
import _set from 'lodash.set';
import _isEqual from 'lodash.isequal';
import _cloneDeep from 'lodash.clonedeep';
import VueRouter from 'vue-router';
import { Map } from 'mapbox-gl';
import router from '../router';

// Needed because of this issue https://github.com/vuejs/vue-router/issues/2157
export interface RouterWithHistoryType extends VueRouter {
  history: {
    current: {
      query: Record<string, string>;
    };
  };
}

export type UrlAttributeConfigType = {
  isJSON?: boolean;
  isArray?: boolean;
  isNumber?: boolean;
  isBoolean?: boolean;
  isLayerLabelArray?: boolean;
  isPlanLabelArray?: boolean;
  value?: string;
  deleteAfterInitialUse?: boolean;
  routeLocation: string;
  configLocation: string;
};

export type ConfigValueType =
  | string
  | JSON
  | boolean
  | number
  | Array<string | JSON | boolean | number>;

export const LAYERS_SHOWN_ON_MAP_URL_LOCATION = 'layersShownOnMap';
export const MAP_CENTER_URL_LOCATION = 'mapCenter';
export const MAP_ZOOM_URL_LOCATION = 'mapZoom';

export const permittedUrlAttributeConfig: UrlAttributeConfigType[] = [
  {
    isArray: true,
    isNumber: true,
    routeLocation: 'layerIds',
    configLocation: 'layerIds',
  },
  {
    isArray: true,
    isNumber: true,
    routeLocation: 'planIds',
    configLocation: 'planIds',
  },
  {
    isBoolean: true,
    routeLocation: 'closeSidebarInitially',
    configLocation: 'attributes.sidebarConfig.closeSidebarInitially',
  },
  {
    isBoolean: true,
    routeLocation: 'openLegendInitially',
    configLocation: 'attributes.sidebarConfig.openLegendInitially',
  },
  {
    isBoolean: true,
    routeLocation: 'displayCategories',
    configLocation: 'attributes.displayCategories',
  },
  {
    isBoolean: true,
    routeLocation: 'showLayerLegend',
    configLocation: 'attributes.showLayerLegend',
  },
  {
    isNumber: true,
    routeLocation: MAP_ZOOM_URL_LOCATION,
    configLocation: 'attributes.initialMapZoom',
    deleteAfterInitialUse: true,
  },
  {
    isNumber: true,
    routeLocation: 'labelLayerMinZoom',
    configLocation: 'attributes.labelLayerMinZoom',
  },
  {
    isJSON: true,
    routeLocation: MAP_CENTER_URL_LOCATION,
    configLocation: 'attributes.initialMapCenter',
    deleteAfterInitialUse: true,
  },
  {
    isNumber: true,
    routeLocation: 'initialMapThemeId',
    configLocation: 'attributes.initialMapThemeId',
  },
  {
    routeLocation: 'initialBasemap',
    configLocation: 'attributes.initialBasemap',
  },
  {
    isNumber: true,
    isArray: true,
    routeLocation: LAYERS_SHOWN_ON_MAP_URL_LOCATION,
    configLocation: 'attributes.layersShownOnMapLoad',
  },
  {
    routeLocation: 'initiallySelectedFeature',
    configLocation: 'initiallySelectedFeature',
  },
];

function parseAttributeValue(
  urlAttributeConfig: UrlAttributeConfigType,
  value: string,
): string | JSON | boolean | number {
  const { isJSON, isNumber, isBoolean } = urlAttributeConfig;
  if (isJSON) {
    return JSON.parse(value);
  }
  if (isNumber) {
    return parseInt(value, 10);
  }
  if (isBoolean) {
    return value === 'true';
  }
  return value;
}

function parseUrlAttributeConfig(urlAttributeConfig: UrlAttributeConfigType): {
  configLocation: string | undefined;
  configValue: ConfigValueType | undefined;
} {
  const { isArray, configLocation, routeLocation } = urlAttributeConfig;
  const value = router.currentRoute.query[routeLocation] as string;
  let configValue;
  if (value) {
    if (isArray) {
      const arrayOfStrings: string[] = value.replace(', ', ',').split(',');
      configValue = arrayOfStrings.map((stringValue) =>
        parseAttributeValue(urlAttributeConfig, stringValue),
      );
    } else {
      configValue = parseAttributeValue(urlAttributeConfig, value);
    }
  }
  return { configLocation, configValue };
}

export type ViewConfigState = {
  id?: number;
  attributes?: ViewConfigAttributes;
  layerIds?: number[];
  planIds?: number[];
  styles: ViewConfigStyleValues;
  tileServer?: string;
  publicApiToken?: string;
  initialMapTheme?: LayerStyle[] | null;
  initiallySelectedFeature?: string | null;
};

export const mapUrlParamsToViewConfig = (viewConfig: ViewConfigState): ViewConfigState => {
  const viewConfigCopy = _cloneDeep(viewConfig);
  const urlConfigParams = permittedUrlAttributeConfig
    .map((urlAttributeConfig: UrlAttributeConfigType) =>
      parseUrlAttributeConfig(urlAttributeConfig),
    )
    .reduce((urlConfig: Record<string, ConfigValueType>, configLocationValue) => {
      const { configLocation, configValue } = configLocationValue;
      if (configLocation && configValue !== undefined) {
        urlConfig[configLocation] = configValue;
      }
      return urlConfig;
    }, {});

  Object.keys(urlConfigParams).forEach((urlConfigParamKey) => {
    if (urlConfigParamKey === 'layerIds') {
      const urlLayerIds = urlConfigParams.layerIds as number[];
      const layerIds = urlLayerIds.filter((id) => viewConfigCopy.layerIds?.includes(id));
      _set(viewConfigCopy, urlConfigParamKey, layerIds);
    } else {
      _set(viewConfigCopy, urlConfigParamKey, urlConfigParams[urlConfigParamKey]);
    }
  });

  return viewConfigCopy;
};

export const setUrlParam = (paramKey: string, paramValue: string | null): void => {
  const { currentRoute } = router;
  const updatedQueryParams = _cloneDeep(currentRoute.query);
  if (paramValue && paramValue.length > 0) {
    updatedQueryParams[paramKey] = paramValue;
  } else {
    delete updatedQueryParams[paramKey];
  }
  if (!_isEqual(currentRoute.query, updatedQueryParams)) {
    router.replace({
      name: currentRoute.name as string,
      params: currentRoute.params,
      hash: currentRoute.hash,
      query: updatedQueryParams,
    });
  }
};

export const updateUrlMapLocation = (map: Map | null): void => {
  if (map) {
    const mapZoom = map.getZoom();
    const mapCenter = map.getCenter();
    setUrlParam(MAP_ZOOM_URL_LOCATION, mapZoom ? `${mapZoom}` : null);
    setUrlParam(
      MAP_CENTER_URL_LOCATION,
      mapCenter ? `{ "lat":${mapCenter.lat}, "lng":${mapCenter.lng} }` : null,
    );
  }
};
