import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import getViewPathParts from '@/util/getViewPathParts';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/map',
    name: 'map',
    component: () => import(/* webpackChunkName: "map" */ '../views/MapView.vue'),
  },
  {
    path: '*',
    name: 'root',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: getViewPathParts().baseUrl,
  routes,
});

export default router;
