import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { Position } from '@turf/helpers';
import type { RootState } from '@/store';
import { MutationTypes } from '@/constants';
import { ContentBlockConfig, RedirectionConfig } from '@/types';
import {
  getPolygonIntersectionConfigs,
  pointInPolygonIntersections,
  getMatchingIntersectionRelationshipPolygons,
  mapConfigContentFromPolygons,
} from '@/util/polygonIntersections';
import {
  PolygonIntersectionIdentifier,
  PolygonIntersectionRelationship,
} from '@/types/polygonIntersections';

export class PolygonIntersection {
  isLoadingPolygonIntersectionRelationships = false;
  polygonIntersectionRelationships: PolygonIntersectionRelationship[] | null = null;
}

export const mutations: MutationTree<PolygonIntersection> = {
  [MutationTypes.SET_POLYGON_INTERSECTIONS](
    state: PolygonIntersection,
    polygonIntersectionRelationships: PolygonIntersectionRelationship[],
  ) {
    state.polygonIntersectionRelationships = polygonIntersectionRelationships;
  },
  [MutationTypes.SET_POLYGON_INTERSECTION_LOADING_STATUS](
    state: PolygonIntersection,
    isLoading: boolean,
  ) {
    state.isLoadingPolygonIntersectionRelationships = isLoading;
  },
};

export const getters: GetterTree<PolygonIntersection, RootState> = {
  getMappedPolygonIntersectionConfig: (state) => (config: ContentBlockConfig) => {
    if (
      state.polygonIntersectionRelationships &&
      state.polygonIntersectionRelationships?.length > 0 &&
      config.polygonIntersectionMapping?.layerId
    ) {
      const featurePolygonIntersectionIdentifier: PolygonIntersectionIdentifier = {
        planIds: config.polygonIntersectionMapping?.planIds ?? [],
        layerId: config.polygonIntersectionMapping?.layerId,
      };
      const polygons = getMatchingIntersectionRelationshipPolygons(
        featurePolygonIntersectionIdentifier,
        state.polygonIntersectionRelationships,
      );
      return mapConfigContentFromPolygons(config, polygons) ?? config;
    }
    return config;
  },
};

export const actions: ActionTree<PolygonIntersection, RootState> = {
  async setPolygonIntersectionRelationships({ commit, rootState }, { coordinates }) {
    commit(MutationTypes.SET_POLYGON_INTERSECTION_LOADING_STATUS, true);
    const contentBlocks: ContentBlockConfig[] =
      rootState.config.viewAttributes?.sidebarConfig?.contentBlocks ?? [];
    const redirect: RedirectionConfig | undefined =
      rootState.config.viewAttributes?.submissionConfig?.redirect;

    const polygonIntersectionConfigs = getPolygonIntersectionConfigs(contentBlocks, redirect);
    const selectedFeatureCoordinates = <Position>coordinates;

    const polygonIntersectionRelationships: PolygonIntersectionRelationship[] =
      await pointInPolygonIntersections(polygonIntersectionConfigs, selectedFeatureCoordinates);

    commit(MutationTypes.SET_POLYGON_INTERSECTIONS, polygonIntersectionRelationships);
    commit(MutationTypes.SET_POLYGON_INTERSECTION_LOADING_STATUS, false);
  },
  resetPolygonIntersections({ commit }) {
    commit(MutationTypes.SET_POLYGON_INTERSECTIONS, null);
  },
};

export default {
  namespaced: true,
  state: (): PolygonIntersection => new PolygonIntersection(),
  getters,
  mutations,
  actions,
};
