




















































import Vue from 'vue';
import { mapState } from 'vuex';
import _debounce from 'lodash.debounce';
import ReadOnlyBanner from './util/ReadOnlyBanner.vue';

export default Vue.extend({
  name: 'shared-header',
  components: {
    ReadOnlyBanner,
  },
  props: {
    displayShareButton: { type: Boolean, default: false },
  },
  data() {
    return {
      titlesTooltipEnabled: false,
      showTitlesTooltip: false,
    };
  },
  computed: {
    ...mapState('config', ['viewAttributes', 'readOnlyMode']),
    logoLocation(): string {
      return (
        this.viewAttributes.logo?.headerLocation || 'https://static.vetro.io/logos/vetro_logo.svg'
      );
    },
    logoLink(): string {
      return this.viewAttributes.logo?.headerLink || 'https://www.vetrofibermap.com';
    },
    organizationTitle(): string {
      return this.viewAttributes?.organizationTitle;
    },
    viewTitle(): string | undefined {
      return this.viewAttributes?.viewTitle;
    },
    bannerActive(): boolean {
      return this.readOnlyMode;
    },
  },
  mounted() {
    this.handleTooltipDisplay = _debounce(this.handleTooltipDisplay, 200);

    this.handleTooltipDisplay();
    window.addEventListener('resize', this.handleTooltipDisplay);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.handleTooltipDisplay);
    });
  },
  methods: {
    openShareUrlModal() {
      this.$bvModal.show('share-url-modal');
    },
    handleTooltipDisplay() {
      const titlesElement = document.getElementById('titles');
      const titlesContainerElement = document.getElementById('titles-wrapper');

      this.titlesTooltipEnabled =
        titlesElement && titlesContainerElement
          ? titlesElement.offsetWidth === titlesContainerElement.offsetWidth
          : false;
    },
  },
});
