







import Vue from 'vue';
import ShareUrlModal from './ShareUrlModal.vue';
import SubmissionModal from './SubmissionModal.vue';

export default Vue.extend({
  name: 'modals-container',
  components: {
    ShareUrlModal,
    SubmissionModal,
  },
});
