import axios, { AxiosError } from 'axios';
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import type { RootState } from '@/store';
import type {
  ViewConfigAttributes,
  VetroResponse,
  LayerStyle,
  GoogleTagManagerConfig,
  SuccessfulVetroResponse,
} from '@/types';
import { isFailedResponse, SubmissionConfig } from '@/types';
import { MutationTypes, SELECTION_TYPE } from '@/constants';
import getViewPathParts from '@/util/getViewPathParts';
import { createStyleClasses, setFaviconHref, setSiteTitle } from '@/util/configToDocumentUpdates';
import { authUrlGenerator } from '@/util/getRedirectUrl';
import { ViewConfigStyleValues } from '@/types/styles';
import { mapUrlParamsToViewConfig, ViewConfigState } from '@/util/urlAttributesParser';

export class ConfigState {
  viewKey: string;
  viewAttributes: ViewConfigAttributes | null = null;
  layerIds?: number[];
  styles: ViewConfigStyleValues | null = null;
  tileServers?: string[];
  publicApiToken?: string;
  planIds: number[] = [];
  initialMapTheme: LayerStyle[] | null = null;
  initiallySelectedFeature: string | null = null;
  readOnlyMode: boolean;
  readOnlyMessage: string;

  constructor(viewKey: string) {
    this.viewKey = viewKey;
    this.readOnlyMode = false;
    this.readOnlyMessage = '';
  }
}

export const mutations: MutationTree<ConfigState> = {
  [MutationTypes.SET_CONFIG](
    state: ConfigState,
    {
      attributes,
      layerIds,
      planIds,
      styles,
      tileServer,
      publicApiToken,
      initialMapTheme,
      initiallySelectedFeature,
      readOnlyMode,
      readOnlyMessage,
    }: {
      attributes: ViewConfigAttributes;
      layerIds: number[];
      planIds: number[];
      styles: ViewConfigStyleValues;
      tileServer: string | string[];
      publicApiToken: string;
      initialMapTheme: LayerStyle[] | null;
      initiallySelectedFeature: string | null;
      readOnlyMode: boolean;
      readOnlyMessage: string;
    },
  ) {
    state.viewAttributes = attributes;
    state.layerIds = layerIds;
    state.styles = styles;
    state.tileServers = Array.isArray(tileServer) ? tileServer : [tileServer];
    state.publicApiToken = publicApiToken;
    state.planIds = planIds;
    state.initialMapTheme = initialMapTheme;
    state.initiallySelectedFeature = initiallySelectedFeature;
    state.readOnlyMode = readOnlyMode;
    state.readOnlyMessage =
      readOnlyMessage ||
      'This map is currently undergoing scheduled maintenance, some features may not be available at this time.';
  },
};

export const actions: ActionTree<ConfigState, RootState> = {
  async initializeViewConfig({ commit, dispatch, state }) {
    try {
      const { data: response }: { data: VetroResponse<ViewConfigState> } = await axios.get(
        `/v2/shared_views/views/${state.viewKey}`,
        { withCredentials: true },
      );
      if (isFailedResponse(response)) {
        const error = new Error(`Failed to retrieve view config for ${state.viewKey}`);
        dispatch('error/setCriticalError', error, { root: true });
        throw error;
      }
      const parsedViewConfig: ViewConfigState = mapUrlParamsToViewConfig(
        (response as SuccessfulVetroResponse<ViewConfigState>).result,
      );

      if (parsedViewConfig?.attributes?.private) {
        const { data: userResponse }: { data: Record<string, unknown> } = await axios.get(
          `/v2/user`,
          { withCredentials: true },
        );
        const user = userResponse.result;
        dispatch('user/setUserInfo', user, { root: true });
        // Ensure all future requests use withCredentials if a private SV
        axios.interceptors.request.use((axiosConfig) => {
          axiosConfig.withCredentials = true;
          if (parsedViewConfig.id) {
            axiosConfig.headers = {
              'shared-view-id': parsedViewConfig.id,
            };
          }

          return axiosConfig;
        });
      } else {
        // All future requests should use the retrieved token
        axios.interceptors.request.use((axiosConfig) => {
          const existingParams = axiosConfig.params || {};
          axiosConfig.params = { token: parsedViewConfig.publicApiToken, ...existingParams };
          return axiosConfig;
        });
      }

      commit(MutationTypes.SET_CONFIG, parsedViewConfig);
      createStyleClasses(parsedViewConfig.styles);
      setFaviconHref(state.viewAttributes?.logo?.faviconLocation);
      setSiteTitle(state.viewAttributes?.viewTitle);
      return parsedViewConfig;
    } catch (e) {
      if ((e as AxiosError)?.response?.status === 401) {
        window.location.href = authUrlGenerator(window.location);
      } else if ((e as AxiosError)?.response?.status === 404) {
        return 404;
      }
      return false;
    }
  },
  resetState({ dispatch }) {
    dispatch('features/resetFeatureState', null, { root: true });
    dispatch('search/resetSearchState', null, { root: true });
    dispatch('sidebar/resetSidebarState', null, { root: true });
    dispatch('map/resetActiveLocation', null, { root: true });
  },
};

export const getters: GetterTree<ConfigState, RootState> = {
  googleTagManagerPublicId(state): undefined | string {
    const currentGTMConfigValue: undefined | string | GoogleTagManagerConfig =
      state.viewAttributes?.googleTagManagerPublicId;
    let gtmContainerId;
    switch (typeof currentGTMConfigValue) {
      case 'string': {
        gtmContainerId = currentGTMConfigValue;
        break;
      }
      case 'object': {
        if (typeof currentGTMConfigValue !== 'string' && currentGTMConfigValue.isActive) {
          gtmContainerId = currentGTMConfigValue.publicKey;
        }
        break;
      }
      default:
        gtmContainerId = undefined;
    }
    return gtmContainerId;
  },
  configHasLoaded(state): boolean {
    return Boolean(state.viewAttributes);
  },
  getViewKey(state): string {
    return state.viewKey;
  },
  getViewAttributes(state): ViewConfigAttributes | null {
    return state.viewAttributes;
  },
  getStyles(state): ViewConfigStyleValues | null {
    return state.styles;
  },
  getLayerIds(state): number[] | undefined {
    return state.layerIds;
  },
  getTileServers(state): string[] | undefined {
    return state.tileServers;
  },
  getPublicApiToken(state): string | undefined {
    return state.publicApiToken;
  },
  getSubmissionConfig(state): SubmissionConfig | undefined {
    return state.viewAttributes?.submissionConfig;
  },
  isFeatureSelectionEnabled(state): boolean {
    return state.viewAttributes?.selectionType === SELECTION_TYPE.FEATURE_SELECT;
  },
  canViewSubmittedComments(state): boolean {
    return state.viewAttributes?.sidebarConfig?.displaySubmittedComments ?? false;
  },
  commentableLayerIds(state): Set<number> | undefined {
    const commentableLayerIds = state.viewAttributes?.layersToShowFormFor ?? state.layerIds ?? [];
    const parentLayerId = state.viewAttributes?.submissionConfig?.parentLayerId;
    return parentLayerId
      ? new Set([...commentableLayerIds, parentLayerId])
      : new Set(commentableLayerIds);
  },
  parentlessCommentsEnabled(state): boolean {
    return state.viewAttributes?.allowParentlessComments ?? false;
  },
  allowCommentOnSelectedFeature(state): boolean {
    return state.viewAttributes?.allowCommentOnSelectedFeature ?? true;
  },
  showDescriptions(state): boolean {
    return state.viewAttributes?.showDescriptions ?? false;
  },
  isPrivateView(state): boolean {
    return state.viewAttributes?.private ?? false;
  },
  useImperialUnits(state): boolean {
    return state.viewAttributes?.preferredUnit !== 'metric';
  },
};

export default {
  namespaced: true,
  state: (): ConfigState => new ConfigState(getViewPathParts().viewKey),
  mutations,
  actions,
  getters,
};
