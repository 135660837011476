import type { Point } from '@turf/helpers';
import { CONTENT_BLOCK_INPUT_TYPES } from '@/types/contentBlocks';
import type {
  VetroResponse,
  SuccessfulVetroResponse,
  FailedVetroResponse,
  NullableGeometry,
  ContentBlockConfig,
  InputBlockConfig,
  Layer,
  Category,
  CategorizedSymbol,
} from '@/types';

export const isSuccessfulResponse = (
  response: VetroResponse,
): response is SuccessfulVetroResponse => {
  return response.success;
};
export const isFailedResponse = (response: VetroResponse): response is FailedVetroResponse => {
  return !response.success;
};

export const isPointGeometry = (geom: NullableGeometry | undefined): geom is Point => {
  return geom?.type === 'Point';
};

export const isInputBlockConfig = (block: ContentBlockConfig): block is InputBlockConfig => {
  return CONTENT_BLOCK_INPUT_TYPES.some((t) => t === block.type);
};

export const isLayer = (layer: Layer | Category | CategorizedSymbol): layer is Layer => {
  return !!(layer as Layer).layerType;
};

export const isCategorizedSymbol = (
  categorizedStyle: Layer | Category | CategorizedSymbol,
): categorizedStyle is CategorizedSymbol => {
  return !!(categorizedStyle as CategorizedSymbol).attributeValue;
};
