import { MutationTree, ActionTree, GetterTree } from 'vuex';
import axios from 'axios';
import { isFailedResponse, VetroResponse, FeatureImageAttributeDomainResponse } from '@/types';

import type { RootState } from '@/store';
import { MutationTypes } from '@/constants';

export class UserState {
  userInfo = {};
  imageAttributeDomain: string | null = null;
  fileAttributeDomain: string | null = null;
}

export const mutations: MutationTree<UserState> = {
  [MutationTypes.SET_USER_INFO](state: UserState, userInfo) {
    state.userInfo = userInfo;
  },
  [MutationTypes.SET_IMAGE_ATTRIBUTE_DOMAIN](state: UserState, domain: string) {
    state.imageAttributeDomain = domain;
  },
  [MutationTypes.SET_FILE_ATTRIBUTE_DOMAIN](state: UserState, domain: string) {
    state.fileAttributeDomain = domain;
  },
};

export const actions: ActionTree<UserState, RootState> = {
  setUserInfo({ commit }, userInfo: Record<string, unknown>): void {
    commit(MutationTypes.SET_USER_INFO, userInfo);
  },
  async fetchCloudfrontDomains({ commit, dispatch }) {
    const { data: response }: { data: VetroResponse<FeatureImageAttributeDomainResponse> } =
      await axios.get('/v2/environment/cloud_front_domains');

    if (isFailedResponse(response)) {
      const error = new Error(`Failed to fetch feature attribute image domains.`);
      dispatch('error/setCriticalError', error, { root: true });
      throw error;
    }
    commit(MutationTypes.SET_IMAGE_ATTRIBUTE_DOMAIN, response.result.images);
    commit(MutationTypes.SET_FILE_ATTRIBUTE_DOMAIN, response.result.attributeDocuments);
  },
};

export const getters: GetterTree<UserState, RootState> = {
  userInfo(state): Record<string, unknown> {
    return state.userInfo;
  },
};

export default {
  namespaced: true,
  state: (): UserState => new UserState(),
  mutations,
  getters,
  actions,
};
