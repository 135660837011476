import BootstrapVue from 'bootstrap-vue';
import VetroComponentLibrary from '@vetro/vetro-vue-component-library';
import Vue, { VueConstructor } from 'vue';
import Clipboard from 'vue-clipboard2';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlus,
  faMinus,
  faHome,
  faMap,
  faTimesCircle,
  faSearch,
  faCheckCircle,
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueAutosuggest from 'vue-autosuggest';
import VetroSpinner from '@/components/util/VetroSpinner.vue';
import store from './store';
import './assets/custom.scss';

const { VetroComponents, VetroPlugins } = VetroComponentLibrary;

export default (vue: VueConstructor<Vue>): void => {
  Object.keys(VetroComponents).forEach((name) => {
    vue.component(name, VetroComponents[name]);
  });

  library.add(
    faPlus,
    faMinus,
    faHome,
    faMap,
    faTimesCircle,
    faSearch,
    faCheckCircle,
    faChevronRight,
    faChevronDown,
  );

  vue.component('font-awesome-icon', FontAwesomeIcon);
  vue.component('VetroSpinner', VetroSpinner);

  Clipboard.config.autoSetContainer = true;
  vue.use(Clipboard);
  vue.use(BootstrapVue);
  vue.use(VetroPlugins.VetroAlert, { store });
  vue.use(VueAutosuggest);
};
