import _uniqWith from 'lodash.uniqwith';
import _cloneDeep from 'lodash.clonedeep';
import { Polygon, Position } from '@turf/helpers';
import { ContentBlockConfig, Feature, RedirectionConfig } from '@/types';
import {
  PolygonIntersectionIdentifier,
  PolygonIntersectionRelationship,
} from '@/types/polygonIntersections';
import { mapPolygonsToContent, pointInPolygonSearch } from './features';

export const arePolygonIntersectionIdentifiersEqual = (
  a: PolygonIntersectionIdentifier,
  b: PolygonIntersectionIdentifier,
): boolean => {
  const hasSameLayer = a.layerId === b.layerId;
  const hasSameNumPlans = a.planIds.length === b.planIds.length;
  const hasSamePlans = a.planIds.every((id) => b.planIds.includes(id));
  return hasSameLayer && hasSameNumPlans && hasSamePlans;
};

export const getPolygonIntersectionConfigs = (
  contentBlocks: ContentBlockConfig[],
  redirect: RedirectionConfig | undefined,
): PolygonIntersectionIdentifier[] => {
  let polygonIntersectionIdentifiers: PolygonIntersectionIdentifier[] = [];
  if (contentBlocks) {
    polygonIntersectionIdentifiers = contentBlocks.reduce((prev, current) => {
      if (
        current.polygonIntersectionMapping?.layerId &&
        current.polygonIntersectionMapping?.planIds
      ) {
        const { layerId, planIds } = current.polygonIntersectionMapping;
        return [...prev, { layerId, planIds }];
      }
      return prev;
    }, <PolygonIntersectionIdentifier[]>[]);
  }
  if (redirect?.polygonIntersectionMapping) {
    polygonIntersectionIdentifiers.push(redirect.polygonIntersectionMapping);
  }
  const mappedPolygonIntersectionsToIdentifiers: PolygonIntersectionIdentifier[] =
    polygonIntersectionIdentifiers.map((pi) => ({
      planIds: pi.planIds,
      layerId: pi.layerId,
    }));

  return _uniqWith(mappedPolygonIntersectionsToIdentifiers, arePolygonIntersectionIdentifiersEqual);
};

export const pointInPolygonIntersections = (
  polygonIntersectionConfigs: PolygonIntersectionIdentifier[],
  selectedFeatureCoordinates: Position,
): Promise<PolygonIntersectionRelationship[]> =>
  Promise.all(
    polygonIntersectionConfigs.map(async (polygonIntersection) => {
      const { layerId } = polygonIntersection;
      const { planIds } = polygonIntersection;
      const polygons: Feature<Polygon>[] = await pointInPolygonSearch(
        selectedFeatureCoordinates,
        planIds,
        layerId,
      );
      return { polygons, layerId, planIds };
    }),
  );

export const getMatchingIntersectionRelationshipPolygons = (
  intersectionIdentifier: PolygonIntersectionIdentifier,
  polygonRelationships: PolygonIntersectionRelationship[],
): Feature<Polygon>[] =>
  polygonRelationships.find((polygonRelationship) =>
    arePolygonIntersectionIdentifiersEqual(polygonRelationship, intersectionIdentifier),
  )?.polygons ?? [];

export const mapConfigContentFromPolygons = (
  config: ContentBlockConfig,
  intersectingPolygons: Feature<Polygon>[] | null,
): ContentBlockConfig | undefined => {
  if (
    config.polygonIntersectionMapping &&
    intersectingPolygons &&
    intersectingPolygons.length > 0
  ) {
    const mappedContent = mapPolygonsToContent(
      intersectingPolygons,
      config.polygonIntersectionMapping,
    );

    let content;
    if (config.polygonIntersectionMapping.showMultiple && mappedContent.length > 1) {
      content = mappedContent.join('');
    } else {
      [content] = mappedContent;
    }
    const clonedConfig = _cloneDeep(config);
    if (content && typeof content === 'string') {
      clonedConfig.defaultContent = content ?? config.defaultContent;
      return clonedConfig;
    }
    return <ContentBlockConfig>content ?? clonedConfig;
  }
  return config;
};
