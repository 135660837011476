import { FeatureTypes, FeatureTables } from './features';

export enum ExternalSourceType {
  Raster = 'raster',
  Vector = 'vector',
  GeoJson = 'geojson',
}

export enum LayerType {
  Geom = 'geom',
  Tile = 'tile',
}

export type GeomType = string | null;

export interface Attribute {
  defaultValue: string | number | null;
  displayFormat: string | null;
  displayOrder: number;
  isHidden: boolean;
  htmlElement: string;
  htmlInputType: string;
  id: number;
  isExpenseSizeAttribute: boolean;
  isRequired: boolean;
  maximum: number | null;
  minimum: number | null;
  permittedValues: Array<string | number> | null;
  permittedValueDescriptions: Record<string, string>;
  sourceLayerId: number | null;
  description: string | null;
}

export interface LayerSymbol {
  iconClass: string;
  iconSize: number;
  strokeColor: string;
  strokeOpactiy: number;
  strokeWeight: number;
  displayOrder?: number;
}

export interface CategorizedSymbol {
  attributeValue: string;
  symbol: LayerSymbol;
  description?: string | null;
}

export interface CategorizedSymbolWithLayerInfo extends CategorizedSymbol {
  attributeLabel: string | null;
  layerId: number;
  geomType: GeomType;
}

export interface LayerStyle {
  categorizedAttributeLabel: string | null;
  labels: string[];
  labelsEnabled: boolean;
  hideLayerPrefix: boolean;
  hideCategorizedAttributes: boolean | null;
  layerId: number;
  styleThemeId: number;
  symbols?: Record<string, LayerSymbol>; // Sometimes we manually remove a layer's symbols to hide them in the legend
}

export interface Layer {
  availableAttributes: Record<string, Attribute>;
  categoryId: number;
  createdBy: number;
  createdTime: string;
  enabled: boolean;
  externalRasterScheme: string | null;
  externalSourceType: ExternalSourceType | null;
  externalUrl: string | null;
  externalVectorOid: string | null;
  externalVectorSourceId: string | null;
  featureTable: FeatureTables;
  featureType: FeatureTypes;
  geomType: GeomType;
  hasExternalAuth: boolean;
  id: number;
  isActive: boolean;
  isEditable: boolean;
  isExportable: boolean;
  isImported: boolean;
  label: string;
  lastModifiedTime: string | null;
  layerType: LayerType;
  mappable: boolean;
  rasterId: string | null;
  sortOrder: number;
  style: LayerStyle;
  description?: string | null;
}

export interface Category {
  id: number;
  isExpanded: boolean;
  isImportTarget: boolean;
  isSource: boolean;
  label: string;
  sortOrder: number;
  description?: string | null;
}

export interface CategorizedAttributeStatusItem {
  layerId: number;
  attributeValue: string;
  attributeStatus: boolean;
}
