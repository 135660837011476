import { MutationTree, ActionTree, GetterTree } from 'vuex';

import type { RootState } from '@/store';
import { MutationTypes } from '@/constants';

export class LegendState {
  legendIsActive = false;
}

export const mutations: MutationTree<LegendState> = {
  [MutationTypes.TOGGLE_LAYER_LEGEND](state: LegendState) {
    state.legendIsActive = !state.legendIsActive;
  },
};

export const actions: ActionTree<LegendState, RootState> = {
  toggleLegend({ commit }): void {
    commit(MutationTypes.TOGGLE_LAYER_LEGEND);
  },
};

export const getters: GetterTree<LegendState, RootState> = {
  isLegendEnabled(__state, __getters, rootState): boolean {
    return !!rootState.config.viewAttributes?.showLayerLegend;
  },
  shouldDisplayCategories(__state, __getters, rootState): boolean {
    return !!rootState.config.viewAttributes?.displayCategories;
  },
};

export default {
  namespaced: true,
  state: (): LegendState => new LegendState(),
  mutations,
  actions,
  getters,
};
