import { Store } from 'vuex';
import { RootState } from '@/store';

export const successAlert = (store: Store<RootState>, message: string): void => {
  store.dispatch('alert/successAlert', message, {
    root: true,
  });
};

export const failureAlert = (store: Store<RootState>, message: string): void => {
  store.dispatch('alert/failureAlert', message, {
    root: true,
  });
};
