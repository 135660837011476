















import Vue from 'vue';
import VueMeta from 'vue-meta';
import { mapActions, mapGetters, mapState } from 'vuex';
import SharedHeader from '@/components/SharedHeader.vue';
import CriticalError from '@/components/CriticalError.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import ModalsContainer from '@/components/modals/ModalsContainer.vue';
import store from '@/store';
import { failureAlert } from './util/alert';
import { LAYERS_SHOWN_ON_MAP_URL_LOCATION, setUrlParam } from './util/urlAttributesParser';

Vue.use(VueMeta);

export default Vue.extend({
  name: 'app',
  components: {
    SharedHeader,
    CriticalError,
    LoadingSpinner,
    ModalsContainer,
  },
  data() {
    return { initializationError: null };
  },
  async mounted() {
    try {
      if (!this.configHasLoaded) {
        const res = await this.initializeViewConfig();
        if (res === 404) {
          failureAlert(
            store,
            'Shared View has been disabled. Contact Vetro Support team if this is unexpected.',
          );
          throw new Error('Shared View has been disabled.');
        }
      }
      // Kick off layer retrieval now that we have our config
      this.getLayersAndCategories();

      // Allows us to display images in the feature info panel
      this.fetchCloudfrontDomains();

      // Allows labels to be shown in legend when a layer is categorized by status
      this.fetchFeatureStatuses();

      if (this.$router.currentRoute.name === 'root') {
        this.$router.push({
          name: 'map',
          params: this.$router.currentRoute.params,
          query: this.$router.currentRoute.query, // Preserve any query parameters, like analytics metadata
        });
      }
    } catch (error) {
      this.setCriticalError(error);
    }
  },
  watch: {
    layerStatusMap(newLayerStatusMap): void {
      if (this.viewAttributes.keepUrlUpdated) {
        const displayedLayerIds = Object.keys(newLayerStatusMap).filter(
          (layerId) => newLayerStatusMap[layerId],
        );
        setUrlParam(LAYERS_SHOWN_ON_MAP_URL_LOCATION, displayedLayerIds.join(','));
      }
    },
  },
  computed: {
    ...mapState('layers', ['layerStatusMap']),
    ...mapState('config', ['viewAttributes', 'layerIds']),
    ...mapGetters('config', ['configHasLoaded']),
    ...mapState('error', ['criticalError']),
  },
  methods: {
    ...mapActions('config', ['initializeViewConfig']),
    ...mapActions('layers', ['getLayersAndCategories']),
    ...mapActions('user', ['fetchCloudfrontDomains']),
    ...mapActions('error', ['setCriticalError']),
    ...mapActions('lifecycle', ['fetchFeatureStatuses']),
  },
  metaInfo() {
    const { organizationTitle, viewTitle } = this.viewAttributes || {};

    if (viewTitle) {
      return {
        title: `${viewTitle} - ${organizationTitle}`,
      };
    }

    return { title: organizationTitle };
  },
});
