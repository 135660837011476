import Vue from 'vue';
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { MutationTypes } from '@/constants';
import type { RootState } from '@/store';

export class SpinnersState {
  spinners: Record<string, unknown> = {};
}

export const mutations: MutationTree<SpinnersState> = {
  [MutationTypes.TOGGLE_SPINNER](state, id) {
    Vue.set(state.spinners, id, !state.spinners[id]);
  },
  [MutationTypes.SET_SPINNER_STATE](state, { id, status }) {
    Vue.set(state.spinners, id, status);
  },
};

export const actions: ActionTree<SpinnersState, RootState> = {
  toggleSpinner({ commit }, id) {
    commit(MutationTypes.TOGGLE_SPINNER, id);
  },
  activateSpinner({ commit }, id) {
    commit(MutationTypes.SET_SPINNER_STATE, { id, status: true });
  },
  deactivateSpinner({ commit }, id) {
    commit(MutationTypes.SET_SPINNER_STATE, { id, status: false });
  },
};

export const getters: GetterTree<SpinnersState, RootState> = {
  isActive: (state) => (id: string) => Boolean(state.spinners[id]),
};

export default {
  namespaced: true,
  state: (): SpinnersState => new SpinnersState(),
  actions,
  mutations,
  getters,
};
