
















import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'vetro-spinner',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    spinnerId: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      // size prop must either be string 'small' or a number suffixed by 'px'
      validator(val): boolean {
        if (['small', undefined].includes(val)) return true;
        return Boolean(val.slice(-2) === 'px' && +val.slice(0, -2));
      },
    },
    overlay: { type: Boolean, default: true },
  },
  computed: {
    ...mapGetters('spinners', { spinnerIdIsActive: 'isActive' }),
    style() {
      if (!this.size?.endsWith('px')) return null;

      return {
        width: this.size,
        height: this.size,
      };
    },
    isActive() {
      return this.spinnerIdIsActive(this.spinnerId) || this.show;
    },
  },
});
