import { MutationTree, ActionTree } from 'vuex';
import type { RootState } from '@/store';
import { MutationTypes, MAP_ACTION_TYPE } from '@/constants';
import { MapAction } from '@/types';
import { Position } from '@turf/helpers';

export class MapState {
  mapPositionResetFlag = false;
  activeLocation: Position | null = null;
  mapAction: MapAction = {
    watcherHitCallback: () => {},
    type: MAP_ACTION_TYPE.DO_NOTHING,
    payload: null,
  };
}

export const mutations: MutationTree<MapState> = {
  [MutationTypes.SET_MAP_POSITION_RESET_FLAG](state: MapState) {
    state.mapPositionResetFlag = true;
  },
  [MutationTypes.UNSET_MAP_POSITION_RESET_FLAG](state: MapState) {
    state.mapPositionResetFlag = false;
  },
  [MutationTypes.SET_ACTIVE_LOCATION](state: MapState, coordinates: Position): void {
    state.activeLocation = coordinates;
  },
  [MutationTypes.SET_MAP_ACTION](state, mapAction: MapAction) {
    state.mapAction = mapAction;
  },
};

export const actions: ActionTree<MapState, RootState> = {
  async triggerMapAction({ commit }, action: MapAction) {
    await new Promise((res) => {
      return commit(MutationTypes.SET_MAP_ACTION, { ...action, watcherHitCallback: res });
    });
  },
  setMapPositionResetFlag({ commit }): void {
    commit(MutationTypes.SET_MAP_POSITION_RESET_FLAG);
  },
  unsetMapPositionResetFlag({ commit }): void {
    commit(MutationTypes.UNSET_MAP_POSITION_RESET_FLAG);
  },
  setActiveLocation(
    { commit, dispatch },
    { coordinates, shouldJump = true }: { coordinates: [number, number]; shouldJump: boolean },
  ) {
    commit(MutationTypes.SET_ACTIVE_LOCATION, coordinates);

    if (shouldJump) {
      dispatch('triggerMapAction', { type: MAP_ACTION_TYPE.JUMP, payload: { coordinates } });
    }
  },
  resetActiveLocation({ commit }) {
    commit(MutationTypes.SET_ACTIVE_LOCATION, null);
  },
};

export default {
  namespaced: true,
  state: (): MapState => new MapState(),
  mutations,
  actions,
};
