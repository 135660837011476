import { MutationTree, ActionTree, GetterTree } from 'vuex';
import _uniqBy from 'lodash.uniqby';
import type { RootState } from '@/store';
import { MAX_MULTI_SELECT, MutationTypes } from '@/constants';
import { Feature, NullableGeometry } from '@/types';

export class FeatureState {
  selectedFeatures: Feature[] = [];
  shouldDisplayAndHighlightSelectedFeature = false;
  multiSelectEnabled = false;
  // 'Focus point' refers to either a point on the map at which the user clicks,
  // or the geocoded location of an address search.
  featuresAtFocusPoint: Feature[] = [];
  surveyEnabledSelection = true;
}

export const mutations: MutationTree<FeatureState> = {
  [MutationTypes.SET_SELECTED_FEATURES](state: FeatureState, features: Feature[]) {
    state.selectedFeatures = features;
  },
  [MutationTypes.FILTER_SELTECTED_FEATURES](state: FeatureState, feature: Feature) {
    state.selectedFeatures = state.selectedFeatures.filter(
      (f) => f.xVetro.id !== feature?.xVetro.id,
    );
  },
  [MutationTypes.ADD_FEATURE](state: FeatureState, feature: Feature) {
    state.selectedFeatures.push(feature);
  },
  [MutationTypes.SET_SELECTED_FEATURE_HIGHLIGHT_STATUS](
    state: FeatureState,
    shouldDisplayAndHighlight: boolean,
  ) {
    state.shouldDisplayAndHighlightSelectedFeature = shouldDisplayAndHighlight;
  },
  [MutationTypes.SET_SELECTED_FEATURE_SURVEY_VISIBILITY](
    state: FeatureState,
    surveyEnabledSelection: boolean,
  ) {
    state.surveyEnabledSelection = surveyEnabledSelection;
  },
  [MutationTypes.RESET_FEATURE_STATE](state: FeatureState) {
    Object.assign(state, new FeatureState());
  },
  [MutationTypes.SET_FEATURES_AT_FOCUS_POINT](state: FeatureState, features: Feature[]) {
    state.featuresAtFocusPoint = features;
  },
  [MutationTypes.SET_MULTI_SELECT_ENABLED](state: FeatureState) {
    state.multiSelectEnabled = true;
  },
  [MutationTypes.SET_MULTI_SELECT_DISABLED](state: FeatureState) {
    state.multiSelectEnabled = false;
  },
};

export const actions: ActionTree<FeatureState, RootState> = {
  applySelectionChange(
    { state, commit, dispatch },
    selectionChanges: {
      featuresToSelect: Array<Feature<NullableGeometry>>;
      featuresToDeselect: Array<Feature<NullableGeometry>>;
      isNotApplied: boolean;
    },
  ) {
    const deselectedVetroIds = new Set(
      selectionChanges.featuresToDeselect.map((feature) => feature.xVetro.vetroId),
    );
    const newFeatureSelection = _uniqBy(
      [...state.selectedFeatures, ...selectionChanges.featuresToSelect],
      'xVetro.vetroId',
    ).filter(
      (feature: Feature<NullableGeometry>) => !deselectedVetroIds.has(feature.xVetro.vetroId),
    );
    if (newFeatureSelection.length >= MAX_MULTI_SELECT) {
      dispatch(
        'alert/failureAlert',
        `Maximum number of ${MAX_MULTI_SELECT} selected features exceeded. Please select fewer features and try again.`,
        {
          root: true,
        },
      );
      return;
    }
    commit(MutationTypes.SET_SELECTED_FEATURES, newFeatureSelection);
    commit(MutationTypes.SET_SELECTED_FEATURE_HIGHLIGHT_STATUS, true);
    if (newFeatureSelection.length > 0) dispatch('sidebar/setSurveyActive', true, { root: true });
  },
  setSelectedFeature(
    { state, commit, dispatch, getters },
    {
      feature,
      shouldDisplayAndHighlight = true,
      surveyEnabledSelection = true,
    }: {
      feature: Feature | null;
      shouldDisplayAndHighlight: boolean;
      surveyEnabledSelection: boolean;
    },
  ) {
    const { featureIds } = getters;
    const { multiSelectEnabled } = state;
    if (multiSelectEnabled) {
      if (featureIds.has(feature?.xVetro.id)) {
        commit(MutationTypes.FILTER_SELTECTED_FEATURES, feature);
      } else if (state.selectedFeatures.length >= MAX_MULTI_SELECT) {
        dispatch(
          'alert/failureAlert',
          `Maximum number of ${MAX_MULTI_SELECT} selected features reached. Please deselect some features before selecting more.`,
          {
            root: true,
          },
        );
        return;
      } else if (feature) {
        commit(MutationTypes.ADD_FEATURE, feature);
      }
    } else {
      commit(MutationTypes.RESET_FEATURE_STATE);
      // Sometimes `feature` is null, if so it is taken to mean "clear all selected features"
      if (feature) {
        commit(MutationTypes.ADD_FEATURE, feature);
      }
    }
    commit(MutationTypes.SET_SELECTED_FEATURE_HIGHLIGHT_STATUS, shouldDisplayAndHighlight);
    commit(MutationTypes.SET_SELECTED_FEATURE_SURVEY_VISIBILITY, surveyEnabledSelection);

    if (feature) dispatch('sidebar/setSurveyActive', true, { root: true });
  },
  resetFeatureState({ commit }) {
    commit(MutationTypes.RESET_FEATURE_STATE);
  },
  setFeaturesAtFocusPoint({ commit }, features: Feature[]) {
    commit(MutationTypes.SET_FEATURES_AT_FOCUS_POINT, features);
  },
};

export const getters: GetterTree<FeatureState, RootState> = {
  featureIds: (state: FeatureState) => {
    return new Set(state.selectedFeatures.map((feature) => feature.xVetro.id));
  },
};

export default {
  namespaced: true,
  state: (): FeatureState => new FeatureState(),
  mutations,
  actions,
  getters,
};
