








































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import CloseButton from '@/components/util/CloseButton.vue';
import { SubmissionModalContentConfig } from '@/types';

export default Vue.extend({
  name: 'share-url-modal',
  components: {
    CloseButton,
  },
  data() {
    return {
      redirectOnceModalHidden: false,
    };
  },
  computed: {
    ...mapGetters('config', ['getViewAttributes']),
    ...mapGetters('sidebar', ['getSubmissionRedirectUrl']),
    submissionRedirectUrl(): string {
      return this.getSubmissionRedirectUrl || '';
    },
    submissionModalContent(): SubmissionModalContentConfig {
      return this.getViewAttributes?.submissionConfig?.submissionModalContent || {};
    },
    headerText(): string {
      return this.submissionModalContent?.header ?? 'Submission Successful';
    },
    bodyText(): string {
      return this.submissionModalContent?.body ?? 'Thank you!';
    },
    submissionButtonText(): string {
      return this.submissionModalContent?.submissionButtonText ?? 'Learn More';
    },
    modalDismissalLinkText(): string {
      return this.submissionModalContent?.modalDismissalLinkText ?? 'No thanks';
    },
  },
  methods: {
    ...mapActions('config', ['resetState']),
    close(): void {
      this.$bvModal.hide('submission-modal');
    },
    afterClose() {
      // submissionRedirectUrl is from sidebar state - grabbing it before
      // close() resets the state
      const url = this.submissionRedirectUrl;
      this.resetState();
      if (this.redirectOnceModalHidden) {
        window.location.href = url;
        this.redirectOnceModalHidden = false;
      }
    },
    handleRedirect() {
      this.redirectOnceModalHidden = true;
      this.close();
    },
  },
});
