// Find the Shared View identifier from a given URL
export interface ViewPathParts {
  baseUrl: string;
  viewKey: string;
}

/**
 * Given a URL path, find the identifiers that drive our configuration
 * lookup and app routing behavior
 */
export const getPartsFromPath = (path: string): ViewPathParts | undefined => {
  const pathRegex = /(?<baseUrl>\/view\/(?<viewKey>[\w-.]+))(\/|$)/; // ex: /view/<uuid>/maybe/more/path/parts
  const result = pathRegex.exec(path);
  if (result?.groups?.viewKey) {
    return {
      baseUrl: result.groups.baseUrl,
      viewKey: result.groups.viewKey,
    };
  }
  return undefined;
};

export default (): ViewPathParts => {
  const pathParts = getPartsFromPath(window.location.pathname);
  if (pathParts) {
    return pathParts;
  }

  // If we do not have a `/view/<uuid>`-style path, we fall back to the domain
  //  as our view key, and don't assert that we need a /view path prefix
  return {
    baseUrl: '',
    viewKey: window.location.host,
  };
};
