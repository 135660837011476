
















import Vue from 'vue';
import ClearIcon from '@/assets/icons/clear.svg?inline';
import SmallClearIcon from '@/assets/icons/clear--small.svg?inline';

export default Vue.extend({
  components: {
    ClearIcon,
    SmallClearIcon,
  },
  props: {
    small: { type: Boolean, default: false },
    danger: { type: Boolean, default: false },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
