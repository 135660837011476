import Vue from 'vue';
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { MutationTypes } from '@/constants';
import type { RootState } from '@/store';
import { isMobile } from '@/util/mobile';

export class SidebarState {
  sidebarIsActive: boolean;
  formInErrorState = false;
  surveyData: Record<string, string> = {};
  formValidity: Record<string, boolean> = {};
  submissionRedirectUrl?: string = '';
  surveyActive = false;
  paginationIndex = 0;

  constructor(windowWidth: number) {
    // default to 'collapsed' sidebar if mobile (xs) device
    this.sidebarIsActive = !isMobile(windowWidth);
  }
}

export const mutations: MutationTree<SidebarState> = {
  [MutationTypes.RESET_SIDEBAR_STATE](state: SidebarState): void {
    Object.assign(state, new SidebarState(window.innerWidth));
  },
  [MutationTypes.TOGGLE_SIDEBAR](state: SidebarState): void {
    state.sidebarIsActive = !state.sidebarIsActive;
  },
  [MutationTypes.OPEN_SIDEBAR](state: SidebarState): void {
    state.sidebarIsActive = true;
  },
  [MutationTypes.CLOSE_SIDEBAR](state: SidebarState): void {
    state.sidebarIsActive = false;
  },
  [MutationTypes.SET_ERROR_STATE](state: SidebarState, newState: boolean): void {
    state.formInErrorState = newState;
  },
  [MutationTypes.ADD_SURVEY_DATA](
    state: SidebarState,
    { key, value }: { key: string; value: string | number | boolean | unknown[] },
  ): void {
    Vue.set(state.surveyData, key, value);
  },
  [MutationTypes.SET_FORM_VALIDITY](
    state: SidebarState,
    { key, isValid }: { key: string; isValid: boolean },
  ): void {
    Vue.set(state.formValidity, key, isValid);
  },
  [MutationTypes.SET_SUBMISSION_REDIRECT_URL](state: SidebarState, url: string): void {
    state.submissionRedirectUrl = url;
  },
  [MutationTypes.SET_SURVEY_ACTIVE](state: SidebarState, active: boolean) {
    state.surveyActive = active;
  },
  [MutationTypes.RESET_PAGINATION_INDEX](state: SidebarState) {
    state.paginationIndex = 0;
  },
  [MutationTypes.SET_PAGINATION_INDEX](state: SidebarState, newIndex) {
    state.paginationIndex = newIndex;
  },
};

export const getters: GetterTree<SidebarState, RootState> = {
  getSubmissionRedirectUrl(state): string | undefined {
    return state.submissionRedirectUrl;
  },
  getFormValidity(state): Record<string, boolean> {
    return state.formValidity;
  },
};

export const actions: ActionTree<SidebarState, RootState> = {
  toggleSidebar({ commit }): void {
    commit(MutationTypes.TOGGLE_SIDEBAR);
  },
  openSidebar({ commit }): void {
    commit(MutationTypes.OPEN_SIDEBAR);
  },
  closeSidebar({ commit }): void {
    commit(MutationTypes.CLOSE_SIDEBAR);
  },
  setSurveyData(
    { commit },
    { key, value }: { key: string; value: string | number | boolean | unknown[] },
  ) {
    commit(MutationTypes.ADD_SURVEY_DATA, { key, value });
  },
  setFormValidity({ commit }, { key, isValid }: { key: string; isValid: boolean }) {
    commit(MutationTypes.SET_FORM_VALIDITY, { key, isValid });
  },
  setFormErrorState({ commit }, newState: boolean) {
    commit(MutationTypes.SET_ERROR_STATE, newState);
  },
  setSubmissionRedirectUrl({ commit }, url: string) {
    commit(MutationTypes.SET_SUBMISSION_REDIRECT_URL, url);
  },
  resetSidebarState({ commit }) {
    commit(MutationTypes.RESET_SIDEBAR_STATE);
  },
  setSurveyActive({ commit, dispatch, state }, surveyActive: boolean) {
    if (surveyActive && !state.sidebarIsActive) dispatch('toggleSidebar');

    commit(MutationTypes.SET_SURVEY_ACTIVE, surveyActive);
  },
  handleIndex({ commit, state }, payload: { indexAction: string; selectedFeatures: number }) {
    const { indexAction, selectedFeatures } = payload;
    const firstItem = 0;
    const lastItem = selectedFeatures - 1;

    switch (indexAction) {
      case 'DECREMENT':
        commit(
          MutationTypes.SET_PAGINATION_INDEX,
          state.paginationIndex === firstItem ? lastItem : state.paginationIndex - 1,
        );
        break;

      case 'INCREMENT':
        commit(
          MutationTypes.SET_PAGINATION_INDEX,
          state.paginationIndex === lastItem ? firstItem : state.paginationIndex + 1,
        );
        break;

      case 'CLEAR':
        commit(MutationTypes.RESET_PAGINATION_INDEX);
        break;

      default:
        break;
    }
  },
};

export default {
  namespaced: true,
  state: (): SidebarState => new SidebarState(window.innerWidth),
  mutations,
  actions,
  getters,
};
