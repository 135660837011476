import { ViewConfigStyleValues } from '@/types/styles';

const DEFAULT_STYLES = {
  HEADER_COLOR: '#f8f9fa',
  HEADER_TEXT_COLOR: '#2c3e50',
  BUTTON_COLOR: '#187BB9',
  BUTTON_TEXT_COLOR: 'white',
  BUTTON_HOVER_COLOR: '#187BB9',
  BUTTON_HOVER_TEXT_COLOR: 'white',
};

const configuredStyleSheetTemplate = ({
  headerColor = DEFAULT_STYLES.HEADER_COLOR,
  headerTextColor = DEFAULT_STYLES.HEADER_TEXT_COLOR,
  buttonColor = DEFAULT_STYLES.BUTTON_COLOR,
  buttonTextColor = DEFAULT_STYLES.BUTTON_TEXT_COLOR,
  buttonHoverColor = DEFAULT_STYLES.BUTTON_HOVER_COLOR,
  buttonHoverTextColor = DEFAULT_STYLES.BUTTON_HOVER_TEXT_COLOR,
}: ViewConfigStyleValues) => `
  .configHeaderClass {
    background: ${headerColor} !important; 
    color: ${headerTextColor} !important;
  }

  .btn-primary,
  .btn-primary:focus {
    background :${buttonColor}; 
    color: ${buttonTextColor};
    border: 1px solid ${buttonColor};
  }

  .btn-outline-primary {
    border: 1px solid ${buttonColor};
    color: ${buttonColor};
  }

  .btn-primary:active,
  .btn-primary:hover {
    background: ${buttonHoverColor} !important;
    color: ${buttonHoverTextColor};
  }

  .btn-outline-primary:active {
    background: ${buttonColor} !important;
  }

  .btn-primary:disabled {
    background: ${buttonColor} !important;
  }

  .btn-outline-primary:hover {
    background: ${buttonColor};
    color: ${buttonTextColor};
    border: 1px solid ${buttonColor};
  }
`;

export const createStyleClasses = (styles: ViewConfigStyleValues | null): void => {
  if (styles) {
    const configuredStyleSheet = document.createElement('style');

    configuredStyleSheet.innerHTML = configuredStyleSheetTemplate(styles);
    document.getElementsByTagName('head')[0].appendChild(configuredStyleSheet);
  }
};

export const setFaviconHref = (faviconLocation: string | undefined): void => {
  const faviconElement = document.createElement('link');
  faviconElement.rel = 'icon';
  if (faviconLocation && faviconElement) {
    faviconElement.href = faviconLocation;
  } else if (faviconElement) {
    faviconElement.href = 'https://static.vetro.io/logos/vetro_favicon.ico';
  }
  document.getElementsByTagName('head')[0].appendChild(faviconElement);
};

export const setSiteTitle = (title: string | undefined): void => {
  if (title) {
    document.title = title;
  }
};
